



























































import Vue from "vue";

export default Vue.extend({
  name: "CreatorCatalog",
  data: () => {
    return {
      canAdmin: false,
      objects: [],
      total: 0,
      showError: false,
      error: '',
      success: '',
      showSuccess: false,
      loaded: false,
      limit: 1000,
      offset: 0,
      showNext: true,
    };
  },
  methods: {
    async getResults(): Promise<void> {
      this.objects = [];
      try {
        const response = await this.$http.post(`/mall/user`, {
          username: this.$store.data.user.username,
          compare: 1,
          status: 2,
          limit: this.limit,
          offset: this.offset,
        });
        response.data.object.objects.forEach(obj => {
        if((['Unlimited', '0', null].includes(obj.limit) ||
          obj.quantity < parseInt(obj.limit) ||
          obj.instances < parseInt(obj.limit)) && obj.status !== 0) 
          {
            this.objects.push(obj);
          }
        })
        this.total = this.objects.length;
        this.showSuccess = true;
      } catch (errorResponse: any) {
        if (errorResponse.response.data.error) {
          this.error = errorResponse.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    },
    async addQuantity(objectId): Promise<void>{
      this.showSuccess = false;
      this.showError = false;
      let qty = prompt("Increase quantity of this object\n You will be charged 20% of the total. (Price x Additional Quantity)\n You can add up to 100 at a time.");
      if(qty !== qty.replace(/[^0-9]/g, '')){
        this.success = '';
        this.error = 'Please only use positive whole numbers';
        return;
      }
      if(parseInt(qty) > 100){
        this.success = '';
        this.error = 'You can only add up to 100 at a time.';
        return;
      }
      if(qty !== null && qty !==''){
      try {
        this.error = '';
        this.success = '';
        this.showError = false;
        await this.$http.post("/object/increase_quantity", {
        'objectId': objectId,
        'qty': parseInt(qty.replace(/[^0-9]/g, '')),
        });
        this.objects = [];
        this.getResults();
        this.success = "Quantity added successfully!"
      } catch (errorResponse: any) {
        if (errorResponse.response.data.error) {
          this.error = errorResponse.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }} else {
        this.error = "You can not add more than what the object is limited to!"
      }
    },
  },
	async mounted(): Promise<void> {
    this.loaded = true;
    this.getResults();
  },
  watch: {
  },
});
