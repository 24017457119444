












































































import Vue from "vue";

export default Vue.extend({
  name: "CreatorCatalog",
  data: () => {
    return {
      canAdmin: false,
      objects: [],
      total: 0,
      showError: false,
      error: '',
      success: '',
      showSuccess: false,
      loaded: false,
      limit: 10,
      offset: 0,
      showNext: true,
    };
  },
  methods: {
    async getResults(): Promise<void> {
      this.objects = [];
      try {
        const response = await this.$http.post(`/mall/user`, {
          username: this.$store.data.user.username,
          compare: 4,
          status: 1,
          limit: this.limit,
          offset: this.offset,
        });
        this.objects = response.data.object.objects;
        this.total = response.data.object.total[0].count;
        this.showSuccess = true;
      } catch (errorResponse: any) {
        if (errorResponse.response.data.error) {
          this.error = errorResponse.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    },
    async next() {
      this.offset = this.offset + this.limit;
      await this.getResults();
    },
    async back() {
      this.offset = this.offset - this.limit;
      await this.getResults();
      this.showNext = true;
    },
  },
	async mounted(): Promise<void> {
    this.loaded = true;
    this.getResults();
  },
  watch: {
  },
});
